// here's where we put our global scss styles
// these "globals" are not wrapped in a scoping class
// USE MINIMALLY AND BE CAREFUL!

body {
    margin: 0;
    min-height: 100vh;
    background-image: url(assets/bg-daniel-handl-50percent.jpg);
    background-position: center center; 
    background-size: cover; 
    background-repeat: no-repeat;
    background-attachment: fixed;
}

a {
    text-decoration: none;
}

li {
    color: #fff;
}


